import React, { Component } from 'react'
import axios from 'axios';
import Alert from 'react-s-alert';
import config from '../config'
import TopNav from '../components/TopNav'

import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';

export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = { 
        show_change_password: false,
        username: "",
        email: "",
        company: "",
        current_password: "",
        password: "",
        confirm_password: "" ,
        logoFile: "./images/company_logo_placeholder.png" ,
        logo: null,
        opacity: 0,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.getUserInfo = this.getUserInfo.bind(this);
    this.dndInit = this.dndInit.bind(this);
    this.FileDragHover = this.FileDragHover.bind(this);
    this.FileSelectHandler = this.FileSelectHandler.bind(this);    
  }

  componentDidMount() {    
    this.getUserInfo();
    if (window.File && window.FileList && window.FileReader) {
      this.dndInit();
    }
  }

  dndInit() {
    var fileDrag = document.getElementById("company-logo-container");
    fileDrag.addEventListener("dragover", this.FileDragHover, false);
    fileDrag.addEventListener("dragleave", this.FileDragHover, false);
    fileDrag.addEventListener("drop", this.FileSelectHandler, false);
  }

  FileDragHover(e) {
    e.stopPropagation();
    e.preventDefault();

    if (e.type === "dragover") {
      this.setState({opacity: 1});
    }
  }

  FileSelectHandler(e) {
    this.FileDragHover(e);

    var files = e.target.files || e.dataTransfer.files;
    if (files.length > 0) {
      this.setState({
        logoFile: URL.createObjectURL(files[0]),
        logo: files[0]
      })
    }
  }

  mouseOut() {
    this.setState({opacity: 0});
  }
  
  mouseOver() {        
    this.setState({opacity: 100});
  }

  onChangeFile(event) {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    this.setState({
        logoFile: URL.createObjectURL(file),
        logo: file
    });
  }

  getUserInfo(authToken) {
    var self = this;
    var payload={
        auth_token: localStorage.getItem("silence_token")
    };

    axios.post(config.apiUrl + '/user/get', payload)
    .then(function (response) {        
        
        if (response.status === 200) {
          if (response.data.code === 1) {
            self.setState({
                company: response.data.company,
                email: response.data.email,
                username: response.data.username,
                logoFile: config.baseUrl + response.data.logo
            });
            
          } else {
            localStorage.setItem("silence_token", "");
          }

        } else {
            alert("Server Error");
        }
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    const formData = new FormData();
    formData.append('company', this.state.company)
    formData.append('username', this.state.username)
    formData.append('email', this.state.email)
    formData.append('password', this.state.password)
    formData.append('current_password', this.state.current_password)
    formData.append('confirm_password', this.state.confirm_password)
    formData.append('logo', this.state.logo)
    formData.append('auth_token', localStorage.getItem("silence_token"));

    const upload_config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }

    axios.post(config.apiUrl + '/profile', formData, upload_config)
    .then(function (response) {    
      
        if (response.status === 200) {
          if (response.data.code === 1) {
            
            Alert.success(response.data.msg, {
                position: 'top-right',
                effect: 'slide',
                timeout: 3000
            });

          } else {
            Alert.error(response.data.msg, {
              position: 'top-right',
              effect: 'slide',
              timeout: 3000
            });
          }

        } else {
            alert("Server Error");
        }
    })
    .catch(function (error) {
        console.log(error);
    });
  }
  render() {
    return (
      <div className="container body">
        <div className="main_container">

          <TopNav location="profile" />
          
          <div className="main_contents ta-c" role="main">
            <div className="x_panel half_1">
              <div className="x_title row mb-0">
                <div className="col-md-12 col-sm-12 col-12">
                  <h5>Settings</h5>
                </div>
                <div className="clearfix"></div>
              </div>
              <div className="row">
                <div id="register" className="animate settings_form ">
                  <section className="login_content">
                    <form method="post" encType="multipart/form-data" onSubmit={this.handleSubmit}>
                      <div className="row">
                        <div className="col-md-4 col-sm-4 col-12 ta-c">
                          <div className="ta-c company-logo-container" id="company-logo-container"
                            onMouseOut={() => this.mouseOut()} onMouseOver={() => this.mouseOver()}
                            onClick={()=>this.refs.fileUploader.click()}> 
                            <img src={this.state.logoFile} width="120" height="120" id="company-logo" />
                            <img src="./images/add_company_logo.png" width="120" height="120" style={{opacity: this.state.opacity}} />
                          </div>
                          <input type="file" ref="fileUploader" style={{display:'none'}} onChange={this.onChangeFile.bind(this)} />
                          <span style={{opacity:this.state.opacity,color:'#959595'}}>Drag the logo here<br/>or click to browse</span>
                        </div>
                        <div className="col-md-8 col-sm-8 col-12 signup_company_area">
                          <label htmlFor="company">&nbsp;</label>
                          <input type="text" className="form-control" placeholder="Your company" value={this.state.company} required
                            onChange={(event) => this.setState({company: event.target.value})} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4 col-sm-4 col-12"></div>
                        <div className="col-md-8 col-sm-8 col-12">
                          <div>
                            <label htmlFor="userid">&nbsp;</label>
                            <input type="text" className="form-control" placeholder="Name" value={this.state.username} required
                              onChange={(event) => this.setState({username: event.target.value})} />
                          </div>
                          <div>
                            <label htmlFor="email">&nbsp;</label>
                            <input type="email" className="form-control" name="email" placeholder="Email address" value={this.state.email} required
                              onChange={(event) => this.setState({email: event.target.value})} />
                          </div>
                        </div>
                        <div className="clearfix"></div>    
                      </div>
                      <div className="row">
                        <div className="col-md-4 col-sm-4 col-12"></div>
                        <div className="col-md-8 col-sm-8 col-12">
                          <div>
                            <a id="change-password" onClick={(event) => this.setState({show_change_password: true})}>Change password</a>
                            <div className="clearfix"></div> 
                          </div>
                          {this.state.show_change_password &&
                          <div id="password-panel">
                            <div>
                              <label htmlFor="password"></label>
                              <input type="password" className="form-control" placeholder="Password" 
                                onChange={(event) => this.setState({password: event.target.value})} />
                            </div>
                            <div>
                              <label htmlFor="confirm_password"></label>
                              <input type="password" className="form-control" placeholder="Confirm password" 
                                onChange={(event) => this.setState({confirm_password: event.target.value})} />
                            </div>
                          </div>
                          }
                          <div className="clearfix"></div>
                        </div>
                      </div>
                      
                      <div className="row mt-15">
                        <div className="col-md-6 col-sm-6 col-12 note">
                          <span>Note</span><br/>
                          If you want to delete your account, please<br/>
                          email us at &nbsp;<a href="mailto:support@barix.com" style={{fontSize: '12px'}}>support@barix.com</a>
                        </div>
                        <div className="col-md-6 col-sm-6 col-12">
                          <p className="change_link">
                          </p>
                          <div className="pull-right">
                            <button type="submit" className="btn-login btn-next-step submit">Update Details</button>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    </form>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Alert stack={{limit: 1}} />
      </div>
    )
  }
}
