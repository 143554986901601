import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import axios from 'axios';
import Alert from 'react-s-alert';
import Modal from 'react-bootstrap/Modal';
import Recaptcha from 'react-recaptcha';
import config from '../config'

import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';

export default class LoginContainer extends Component {
  constructor(props) {
    super(props);

    var remember_me = localStorage.getItem("remember_me");

    this.state = {
        username: '',
        password: '',
        forgot_email: '',
        showModal: false,
        remember_me: (remember_me==="true")?true:false,
        forgot_email: '',
        verify_response: "",
        devices_page: false,
        
    }

    this.handleModalClose = this.handleModalClose.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.forgotPassSubmit = this.forgotPassSubmit.bind(this);
    this.onRememberMeClicked = this.onRememberMeClicked.bind(this);    
    this.verifyCallback = this.verifyCallback.bind(this);  
    this.renderDevicesPage = this.renderDevicesPage.bind(this);
  }

  handleModalClose() {
    this.setState({showModal: false})
  }

  handleModalShow() {
    this.setState({showModal: true})
  }

  handleSubmit(event) {
    event.preventDefault();

    var self = this;
    var payload={
        "email": this.state.username,
        "password": this.state.password
    }
    axios.post(config.apiUrl + '/login', payload)
    .then(function (response) {
      if (response.status === 200) {
        if (response.data.code === 1) {
          localStorage.setItem("silence_token", response.data.token);
          self.setState({ devices_page: true });          
          
        } else {
          Alert.error(response.data.msg, {
            position: 'top-right',
            effect: 'slide',
            timeout: 3000
          });
        }

      } else {
          alert("Server Error");
      }
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  forgotPassSubmit(event) {
    event.preventDefault();
    var self = this
    
    var payload={
        "email": this.state.forgot_email,
        "g-recaptcha-response": this.state.verify_response
    }

    axios.post(config.apiUrl + '/forgotpass', payload)
    .then(function (response) {
      if (response.status === 200) {
        self.handleModalClose()

        if (response.data.code === 1) {
          Alert.success(response.data.msg, {
            position: 'top-right',
            effect: 'slide',
            timeout: 3000
          });
        } else {
          Alert.error(response.data.msg, {
            position: 'top-right',
            effect: 'slide',
            timeout: 3000
          });
        }

      } else {
          alert("Server Error");
      }
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  onRememberMeClicked(event) {    
    this.setState({
      remember_me: event.target.checked,
    });
    localStorage.setItem("remember_me", event.target.checked);
  }

    verifyCallback(response) {
        this.setState({
          verify_response: response
        })
    }

  renderDevicesPage() {
    if (this.state.devices_page) {
      return <Redirect to="/devices" />
    }
  }

  render() {
    return (
      <div style={{ background: 'url(./images/background.jpg)', height: '100vh', backgroundSize: 'cover' }}>
        { this.renderDevicesPage() }
        <div className ="login_wrapper">
          <div className ="animate form login_form">
            <form onSubmit={this.handleSubmit}>
              <section className ="login_content">
                <img src="./images/default_logo.png" className="logo" />
                <h1>SILENCEMONITOR</h1>
                
                <div>
                  <label htmlFor="userid">Email address</label>
                  <input type="email" className ="form-control" required onChange={(event) => this.setState({username:event.target.value})} />
                </div>
                <div>
                  <label htmlFor="password">Password</label>
                  <input type="password" className ="form-control" required onChange={(event) => this.setState({password:event.target.value})} />
                </div>
                <div>
                  <input type="checkbox" id="remember_me" ref="remember_me" checked={this.state.remember_me}
                    onChange={this.onRememberMeClicked} />
                  <label htmlFor="remember_me" className ="remember_label">Remember me</label>

                  <a className ="reset_pass" onClick={(event) => this.setState({showModal: true})}>Forgot password?</a>
                  <div className ="clearfix"></div>
                </div>
                <button type="submit" className ="mt-15 btn-login submit">Log In</button>
                <div className ="clearfix"></div>             
              </section>
            </form>
          </div>
        </div>

        <Modal show={this.state.showModal} onHide={this.handleModalClose}
            dialogClassName="reset-password-modal">
          <Modal.Header>
            <Modal.Title>Reset Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.forgotPassSubmit}>
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <label htmlFor="registration_id">Email address</label>
                  <input type="email" className="form-control" required onChange={(event) => this.setState({forgot_email:event.target.value})} />
                </div>
              </div>
              <div className="row mt-20">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <Recaptcha sitekey={config.reCaptchaSiteKey} verifyCallback={this.verifyCallback} />
                </div>
              </div>
              
              <div className="row mt-20">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <a className="modal-cancel" style={{float:'left'}} onClick={this.handleModalClose} >Cancel</a>
                  <div className="next_step pull-right">
                    <button type="submit" className="btn-login btn-next-step mt-0 submit" style={{margin:'0px'}}>Send Email</button>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <Alert stack={{limit: 1}} />
      </div>
      )
  }
}
