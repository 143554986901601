import React, { Component } from 'react';
import config from '../config';

export default class SubscriptionItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: props.selected
        };
        this.toggleChange = this.toggleChange.bind(this);
    }

    componentWillReceiveProps (newProps) {
        if (newProps.selected) {
            this.setState({
                selected: newProps.selected
            });
        }
    }

    toggleChange() {
        var isSelected = this.state.selected;
        this.setState({ selected: !this.state.selected });
        this.props.onSelectedChanged(this.props.device, !isSelected);
    }

    render() {
        var isOnline = false;
        if (this.props.device.nSecondsFromSync <= 3600 && this.props.device.status === "Alive") {
            isOnline = true;
        }

    return (
        <div>
            <input type="checkbox" className="checkbox" id={"device-" + this.props.device.no} checked={this.state.selected} onChange={this.toggleChange}  />
            <label htmlFor={"device-" + this.props.device.no} className="w-100">
                <div className={"subscription-item " + (this.state.selected?"subscription-selected":"")}>
                    <div style={left} className="details">
                        <div>
                            <i className="fa fa-circle" style={isOnline?{color:'#20a920',float:'left'}:{color:'#d42928',float:'left'}}></i>
                            <div className="deivce-name">{this.props.device.project_name}</div>&nbsp;({this.props.device.project_key})
                            <span className="left-days">
                              {this.props.device.nDaysLeft > 0 && this.props.device.nDaysLeft + ' days left'}
                              {this.props.device.nDaysLeft <= 0 && 'Expired'}
                            </span>
                        </div>
                        <div className="audio-details">
                            Audio messages: {this.props.device.audios.length} | Users subscribed: {this.props.device.nSubscribers}
                        </div>
                    </div>
                    <div className="price">
                        <span>{config.licenseCost}</span>/year
                    </div>
                    <div className="clearfix" />
                </div>  
            </label>
        </div>
    )}
}

const left = {
    float: 'left'
},

right = {
    float: 'right'
}
