import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import NavDropdown from 'react-bootstrap/NavDropdown';
import Alert from 'react-s-alert';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import config from '../config'

import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import { timingSafeEqual } from 'crypto';

export default class TopNav extends Component {
  constructor(props) {
    super(props);
    this.state = { 
        username: "",
        email: "",
        showModal: false,
        is_admin: 0,
        login_page: false,
        devices_page: false,
        profile_page: false,
        payment_page: false,
        help_page: false,
        users_page: false
    };

    this.getUserInfo = this.getUserInfo.bind(this);
    this.logOut = this.logOut.bind(this);
    this.handleModalClose =this.handleModalClose.bind(this);
    this.uploadPackage = this.uploadPackage.bind(this);
    this.renderNextPage = this.renderNextPage.bind(this);
    
  }

  componentDidMount() {
    this.getUserInfo();
  }

  getUserInfo() {    
    var self = this;
    var payload={
        auth_token: localStorage.getItem("silence_token")
    };

    axios.post(config.apiUrl + '/user/get', payload)
    .then(function (response) {    
        if (response.status === 200) {
          if (response.data.code === 1) {
            self.setState({
              email: response.data.email,
              username: response.data.username,
              is_admin: response.data.is_admin
            });
            
          } else {
            localStorage.setItem("silence_token", "");
          }

        } else {
            alert("Server Error");
        }
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  logOut() {    
    var self = this;
    var payload = {
        auth_token: localStorage.getItem("silence_token")
    };

    axios.post(config.apiUrl + '/logout', payload)
    .then(function (response) {

        if (response.status === 200) {
          if (response.data.code === 1) {
            localStorage.setItem("silence_token", "");
            self.setState({login_page: true})

          } else {
            localStorage.setItem("silence_token", "");
            self.setState({login_page: true})
          }

        } else {
            alert("Server Error");
        }
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  handleModalClose() {
    this.setState({
      showModal: false
    })
  }

  uploadPackage() {
    var self = this
    var file = this.refs.fileUploader.files[0];
    if (!file) {
      alert("Please choose the package file.");
      return;
    }

    const formData = new FormData();
    formData.append('file', file)
    formData.append('auth_token', localStorage.getItem("silence_token"));
    
    const upload_config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }

    axios.post(config.apiUrl + '/package', formData, upload_config)
    .then(function (response) {        
      if (response.status === 200) {
        if (response.data.code === 1) {
          self.handleModalClose()

        } else {
          Alert.error(response.data.msg, {
            position: 'top-right',
            effect: 'slide',
            timeout: 3000
          });
        }

      } else {
          alert("Server Error");
      }
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  renderNextPage() {
    if (this.state.login_page) {
      return <Redirect to="/login" />

    } else if (this.state.devices_page && this.props.location !== "devices") {
      return <Redirect to="/devices" />

    } else if (this.state.profile_page && this.props.location !== "profile") {
      return <Redirect to="/profile" />

    } else if (this.state.help_page && this.props.location !== "help") {
      return <Redirect to="/help" />

    } else if (this.state.users_page && this.props.location !== "users") {
      return <Redirect to="/users" />

    } else if (this.state.payment_page && this.props.location !== "payment") {
      return <Redirect to="/subscription" />
    }
  }

  render() {
      return (
          <div className="top_nav">
              {this.renderNextPage()}
              <div className="nav_menu text-center">
                  <nav>
                      <div>SILENCEMONITOR</div>
                      <div>
                          <div className="nav toggle">
                              <a href="devices"><img src="./images/default_logo.png" width="60" /></a>
                          </div>
                          <div className="nav navbar-nav navbar-right block">
                              <NavDropdown title={<i className="fa fa-navicon"></i>} id="basic-nav-dropdown" className="pull-right">
                                  <NavDropdown.Item href="#"><span>{this.state.username}</span><br />{this.state.email}</NavDropdown.Item>
                                  <NavDropdown.Divider />
                                  <NavDropdown.Item onClick={() => this.setState({ profile_page: true })}><i className="fa fa-cog"></i> Settings</NavDropdown.Item>
                                  <NavDropdown.Item onClick={() => this.setState({ devices_page: true })}><i className="fa fa-hdd-o"></i> Manage Devices</NavDropdown.Item>
                                  <NavDropdown.Item href="https://help.barix.com/silencemonitor" target="_blank"><i className="fa fa-question"></i> Help</NavDropdown.Item>
                                  <NavDropdown.Item onClick={this.logOut}><i className="fa fa-sign-out"></i> Log Out</NavDropdown.Item>
                                  <i></i>
                              </NavDropdown>
                              {this.state.is_admin === 1 &&
                                  <li className={this.props.location === "users" ? "underline" : ""}>
                                  <a onClick={() => this.setState({ users_page: true })}>Manage Users</a>
                                  </li>
                              }
                              {this.state.is_admin === 1 &&
                                  <li>
                                  <a className="user-profile dropdown-toggle"
                                      onClick={() => this.setState({ showModal: true })}><i className="fa fa-plus"></i></a>
                                  </li>
                              }
                          </div>
                          <a style={{ display: 'none' }}>Some of your devices require attention!</a>
                          <div className="clearfix" />
                      </div>
                  </nav>
              </div>

              <Modal show={this.state.showModal} onHide={this.handleModalClose} dialogClassName="ConfirmModal">
                  <Modal.Header>
                      <Modal.Title>Upload Package</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                      <form method="post">
                          <input type="file" ref="fileUploader" required />
                      </form>
                  </Modal.Body>
                  <Modal.Footer>
                      <button type="button" className="btn btn-default btn-cancel" onClick={this.handleModalClose} >Cancel</button>
                      <button type="button" className="btn btn-common btn-yes" onClick={this.uploadPackage}>Upload</button>
                  </Modal.Footer>
              </Modal>
          </div>
      )
  }
}