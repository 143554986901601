import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import axios from 'axios';
import config from '../config'
import Alert from 'react-s-alert';
import TopNav from '../components/TopNav'
import User from '../components/User'
import UserDetails from '../components/UserDetails'
import Modal from 'react-bootstrap/Modal';

import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';

export default class Devices extends Component {
  constructor(props) {
    super(props);

    this.state = {
        users: [],
        edit_userid: 0,
        delete_userid: 0,
        opacity: 0,
        logoFile: "./images/company_logo_placeholder.png" ,
        logo: null,
        company: '',
        username: '',
        email: '',
        password: '',
        confirm_password: '',
        filter: '',
        showModal: false,
        showAdminModal: false,
        devices_page: false,
        login_page: false
    };

    this.getAllUsers = this.getAllUsers.bind(this);
    this.onEditClicked = this.onEditClicked.bind(this);
    this.onCancelClicked = this.onCancelClicked.bind(this);
    this.onDeleteClicked = this.onDeleteClicked.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.onUpdatedUser = this.onUpdatedUser.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);

    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleAdminModalClose = this.handleAdminModalClose.bind(this);

    this.dndInit = this.dndInit.bind(this);
    this.FileDragHover = this.FileDragHover.bind(this);
    this.FileSelectHandler = this.FileSelectHandler.bind(this);

    this.renderDevicesPage = this.renderDevicesPage.bind(this);
    this.renderLoginPage = this.renderLoginPage.bind(this);
  }

  componentDidMount() {
    this.getAllUsers();
  }

  dndInit() {
    var fileDrag = document.getElementById("company-logo-container");
    fileDrag.addEventListener("dragover", this.FileDragHover, false);
    fileDrag.addEventListener("dragleave", this.FileDragHover, false);
    fileDrag.addEventListener("drop", this.FileSelectHandler, false);
  }

  FileDragHover(e) {
    e.stopPropagation();
    e.preventDefault();

    if (e.type === "dragover") {
      this.setState({opacity: 1});
    }
  }

  FileSelectHandler(e) {
    this.FileDragHover(e);

    var files = e.target.files || e.dataTransfer.files;

    if (files.length > 0) {
      this.setState({
        logoFile: URL.createObjectURL(files[0]),
        logo: files[0]
      })
    }
  }

  mouseOut() {
    this.setState({opacity: 0});
  }
    
  mouseOver() {        
    this.setState({opacity: 100});
  }

  onChangeFile(event) {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    this.setState({
        logoFile: URL.createObjectURL(file),
        logo: file
    });
  }

  getAllUsers() {
    var self = this;
    var payload={
      auth_token: localStorage.getItem("silence_token")
    };

    axios.post(config.apiUrl + '/users/get', payload)
    .then(function (response) {

      if (response.status === 200) {   
        if (response.data.code === 1) {
          self.setState({users: response.data.users})

        } else if (response.data.code === 2) {
          self.setState({devices_page: true})

        } else {
          localStorage.setItem("silence_token", "");
          self.setState({login_page: true})
        }

      } else {
        alert("Server Error");
      }
    })
    .catch(function (error) {
        console.log(error);
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    
    var self = this;

    const formData = new FormData();
    formData.append('company', this.state.company)
    formData.append('username', this.state.username)
    formData.append('email', this.state.email)
    formData.append('password', this.state.password)
    formData.append('confirm_password', this.state.confirm_password)
    formData.append('logo', this.state.logo)

    var authToken = localStorage.getItem("silence_token");
    if (authToken) {
      formData.append('auth_token', authToken);
    }

    const upload_config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }

    this.handleAdminModalClose()
    axios.post(config.apiUrl + '/user/add', formData, upload_config)
    .then(function (response) {        
        
      if (response.status === 200) {
        if (response.data.code === 1) {
          self.setState({users: response.data.users})

        } else {
          Alert.error(response.data.msg, {
            position: 'top-right',
            effect: 'slide',
            timeout: 3000
          });
        }

      } else {
        alert("Server Error");
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  deleteUser() {
    var self = this;
    var payload={
      auth_token: localStorage.getItem("silence_token") + "aaa",
      userid: this.state.delete_userid
    };

    axios.post(config.apiUrl + '/user/delete', payload)
    .then(function (response) {        
        if (response.status === 200) {   
          if (response.data.code === 1) {
              self.handleModalClose()
              var users = self.state.users.filter(function(user) {
                return user.id !== self.state.delete_userid
              })
              self.setState({users: users})

          } else if (response.data.code === 2) {
            self.setState({devices_page: true})

          } else {
            localStorage.setItem("silence_token", "");
            self.setState({login_page: true})
          }

        } else {
            alert("Server Error");
        }
    })
    .catch(function (error) {
      console.log(error);
    });
  }    

  onUpdatedUser(users) {
    this.setState({users: users})
  }

  onEditClicked(user) {
    this.setState({edit_userid: user.id})
  }

  onDeleteClicked(user) {
    this.setState({
      showModal: true,
      delete_userid: user.id
    })
  }

  onCancelClicked(user) {
    this.setState({edit_userid: 0})
  }

  renderUserItems() {
    const rows = []
    for (let user of this.state.users) {
      if (user.username.indexOf(this.state.filter) !== -1 || user.email.indexOf(this.state.filter) !== -1 || user.company.indexOf(this.state.filter) !== -1) {
        rows.push(
          <User editMode={(this.state.edit_userid === user.id)?true:false} user={user} key={user.id} onEditClicked={this.onEditClicked} />
        )

        if (this.state.edit_userid === user.id) {
          rows.push(
            <UserDetails user={user} key={"user_details_" + user.id}  onDeleteClicked={this.onDeleteClicked} 
                onCancelClicked={this.onCancelClicked}  onUpdatedUser={this.onUpdatedUser} />
          )
        }
      }
    }
    return rows
  }

  onFilterChange(event) {
    this.setState({filter: event.target.value})
  }

  handleModalClose() {
    this.setState({showModal: false})
  }

  handleAdminModalClose() {
    this.setState({showAdminModal: false})
  }

  renderDevicesPage() {
    if (this.state.devices_page) {
      return <Redirect to="/devices" />
    }
  }

  renderLoginPage() {
    if (this.state.login_page) {
      return <Redirect to="/login" />
    }
  }

  render() {
    return (
      <div className="container body">
        { this.renderDevicesPage() }
        { this.renderLoginPage() }
        <div className="main_container">

          <TopNav location="users" />

          <div className="main_contents ta-c" role="main">
            <div className="x_panel half bg-white">
                <div className="x_title row mb-0">
                    <div className="col-md-6 col-sm-6 col-6 search-user">
                        <div className="inner-addon right-addon search-box">
                            <i className="fa fa-search" style={{right:'0px'}}></i>
                            <input type="text" placeholder="Search for name, email or company" className="form-control w_100" 
                                onChange={this.onFilterChange} />
                        </div>  
                    </div>
                    <div className="col-md-6 col-sm-6 col-6 ta-r">
                        <button className="btn btn-add-admin" onClick={(event) => this.setState({showAdminModal: true})}>
                            <i className="fa fa-user-plus" style={{marginLeft:'0px'}}></i>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Add Admin
                        </button>
                    </div>
                    <div className="clearfix"></div>
                </div>
                <div className="responsive">
                  <table id="users_table" className="table table-striped">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email address</th>
                        <th>Company</th>
                        <th>Subscribers</th>
                        <th></th>
                        <th><i className="fa fa-circle" style={{color: '#1d567a'}}></i></th>
                        <th><i className="fa fa-ellipsis-v" style={{color:'black'}}></i></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.renderUserItems()}
                    </tbody>
                  </table>
                </div>
            </div>
          </div>      
        </div>

        <Modal show={this.state.showModal} onHide={this.handleModalClose} dialogClassName="ConfirmModal">
          <Modal.Header>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              Are you sure you want to delete this user? <br />
              This action cannot be undone.
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-default btn-cancel" onClick={this.handleModalClose}>Cancel</button>
            <button type="button" className="btn btn-primary btn-yes" onClick={this.deleteUser}>Yes</button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showAdminModal} onHide={this.handleAdminModalClose}
              dialogClassName="create-admin-modal" onShow={this.dndInit}>
          <Modal.Header>
            <Modal.Title>Add Admin</Modal.Title>
          </Modal.Header>
          <section className="login_content settings_form">
            <form method="post" encType="multipart/form-data" onSubmit={this.handleSubmit}>
              <div className="row">
                <div className="col-md-4 col-sm-4 col-12 ta-c">
                  <div className="ta-c company-logo-container" id="company-logo-container"
                    onMouseOut={() => this.mouseOut()} onMouseOver={() => this.mouseOver()}
                    onClick={()=>this.refs.fileUploader.click()}>

                    <img src={this.state.logoFile} width="120" height="120" id="company-logo" alt="" />
                    <img src="./images/add_company_logo.png" width="120" height="120" alt="" style={{opacity: this.state.opacity}} />
                  </div>
                  <input type="file" name="logo" ref="fileUploader" style={{display:'none'}} onChange={this.onChangeFile.bind(this)} />
                  <span style={{opacity:this.state.opacity,color:'#959595'}}>Drag the logo here<br/>or click to browse</span>
                </div>
                <div className="col-md-8 col-sm-8 col-12 signup_company_area pl-30">
                  <label htmlFor="company" className="w-100">&nbsp;</label>
                  <input type="text" className="form-control mark_field" name="company" placeholder="Admin company" required
                    onChange={(event) => this.setState({company:event.target.value})} />
                  <span className="tooltip z-index-10004">
                    <i className="fa fa-info info"></i>
                    <div className="bottom">
                      <h5>Admin company</h5>
                      <p>This is the name admin subscribers will see. Admin can change the company name later.</p>
                      <i></i>
                    </div>
                  </span>
                </div>
              </div>
              <div className="row pl-20">
                <div className="col-md-4 col-sm-4 col-12"></div>
                <div className="col-md-8 col-sm-8 col-12">
                  <div>
                    <label htmlFor="userid" className="w-100"></label>
                    <input type="text" className="form-control mark_field" name="username" placeholder="Admin username" required
                      onChange={(event) => this.setState({username:event.target.value})} />
                    <span className="tooltip z-index-10003">
                      <i className="fa fa-info info"></i>
                      <div className="bottom">
                        <h5>Admin username</h5>
                        <p>This is admin's personal name for admin account.</p>
                        <i></i>
                      </div>
                    </span>
                  </div>
                  <div>
                    <label htmlFor="email" className="w-100"></label>
                    <input type="email" className="form-control mark_field" name="email" placeholder="Email address" required
                      onChange={(event) => this.setState({email:event.target.value})} />
                    <span className="tooltip z-index-10002">
                      <i className="fa fa-info info"></i>
                      <div className="bottom">
                          <h5>Email address</h5>
                          <p>Admin's companies email address.</p>
                          <i></i>
                      </div>
                    </span>
                  </div>
                  <div>
                    <label htmlFor="password" className="w-100"></label>
                    <input type="password" className="form-control mark_field" name="password" placeholder="Password" required
                      onChange={(event) => this.setState({password:event.target.value})} />
                    <span className="tooltip z-index-10001">
                      <i className="fa fa-info info"></i>
                      <div className="bottom">
                        <h5>Password</h5>
                        <p>Please choose a password with a minimum of 8 characters.</p>
                        <i></i>
                      </div>
                    </span>
                  </div>
                  <div>
                    <label htmlFor="confirm_password" className="w-100"></label>
                    <input type="password" className="form-control mark_field" name="confirm_password" placeholder="Confirm password" required
                      onChange={(event) => this.setState({confirm_password:event.target.value})} />
                    <span className="tooltip z-index-10000">
                      <i className="fa fa-info info"></i>
                      <div className="bottom">
                        <h5>Confirm password</h5>
                        <p>Confirm your password to eliminate errors.</p>
                        <i></i>
                      </div>
                    </span>
                  </div>
                  <div className="clearfix"></div>    
                </div>
              </div>

              <div className="separator mt-20">
                <div style={{padding: '0px 0px 0px 30px'}}>
                  <div className="change_link"><a className="to_register" onClick={this.handleAdminModalClose}> Cancel </a></div>
                  <div className="next_step">
                    <button type="submit" className="btn-login btn-next-step submit">Submit</button>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </form>
          </section>
        </Modal>

        <Alert stack={{limit: 1}} />
      </div>
    )
  }
}
