import React, { Component } from 'react'
import moment from 'moment-timezone'
import Alert from 'react-s-alert';
import axios from 'axios';
import config from '../config'

import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';

export default class AudioPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: this.props.selected,
      status: 0,
      progress: "0:00",
      remaining: "-0:00",
      playPercent: 0,
      track_width: 0
    };

    this.clickedPlayBtn = this.clickedPlayBtn.bind(this);
    this.timeupdate = this.timeupdate.bind(this);
    this.deleteAudio = this.deleteAudio.bind(this);
    this.numberFormat = this.numberFormat.bind(this);
    
    this.url = "";
    this.audio = null;
    this.duration = 0;
    this.timeline_width = 0;
  }

  componentDidMount() {
  }

  componentWillReceiveProps(newProps) {
    if (newProps.selected) {
      this.setState({
          selected: newProps.selected
      })
    } else {
      this.setState({
        selected: newProps.selected,
        status: 0,
        progress: "0:00",
        remaining: "-0:00",
        playPercent: 0,
        track_width: 0
      });

      if (this.audio) {
        this.audio.pause();
      }
    }
  }

  clickedPlayBtn() {
    this.setState({selected: true});

    this.props.onSelected(this.props.audio);

    this.url = config.baseUrl + this.props.audio.path;
    if (this.state.status === 0) {
      this.audio = new Audio(this.url);
    }
    
    if (this.state.status === 0) {
      this.setState({status: 1})
      this.audio.play();

    } else if (this.state.status === 1) {
      this.setState({status: 2});
      this.audio.pause();

    } else if (this.state.status === 2) {
      this.setState({status: 1})
      this.audio.play();
    }
    
    this.audio.addEventListener("canplaythrough", function () {
      this.duration = this.audio.duration;
    }.bind(this), false);

    this.audio.addEventListener("ended", function() {
      this.setState({
          status: 2
      })
    }.bind(this));

    this.audio.addEventListener("timeupdate",  this.timeupdate, false);
  }

  timeupdate() {
    if (this.refs.timeline) {
      this.timeline_width = this.refs.timeline.getBoundingClientRect().width;

      var playPercent = 100 * (this.audio.currentTime / this.duration);
      this.setState({ playPercent: playPercent });
      this.setState({ track_width: (4 + playPercent * this.timeline_width / 100) / this.timeline_width * 100 });

      var currentProgress = Math.round(this.audio.currentTime);
      var soundDuration = Math.round(this.duration);
      var remainingTime = soundDuration - currentProgress;

      if (currentProgress % 60 < 10) {
        this.setState({progress: Math.floor(currentProgress / 60) + ":0" + (currentProgress % 60) })
      } else {
        this.setState({progress: Math.floor(currentProgress / 60) + ":" + (currentProgress % 60) })
      }

      if (remainingTime % 60 < 10) {
        this.setState({remaining: "-" + Math.floor(remainingTime / 60) + ":0" + (remainingTime % 60) });
      } else {
        this.setState({remaining: "-" + Math.floor(remainingTime / 60) + ":" + (remainingTime % 60) });
      }
    } else {
      if (this.audio) this.audio.pause();
    }
  }

  deleteAudio() {
    var self = this;
    var payload = {
      auth_token: localStorage.getItem("silence_token"),
      audio_id: this.props.audio.no
    };

    axios.post(config.apiUrl + '/audio/delete', payload)
    .then(function (response) {
      
      if (response.status === 200) {
        if (response.data.code === 1) {
          self.props.onRemovedAudio(self.props.audio);

        } else {
          Alert.error(response.data.msg, {
            position: 'top-right',
            effect: 'slide',
            timeout: 3000
          });
        }

      } else {
        alert("Server Error");
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  numberFormat(n) {
    if (n >= 1000) {
      return n;
    } else if (n >= 100) {
      return "0" + n;
    } else if (n >= 10) {
      return "00" + n;
    } else {
      return "000" + n;
    }
  }

  render() {
    return (
      <div>
        <div className="audioplayer row" style={(this.state.selected?audioplayer_selected:{})} >
          <a className="btn-play-alert" onClick={this.clickedPlayBtn}>
            <img src="./images/ic_play.png" style={(this.state.status === 0)?{ display: 'block' }:{ display: 'none' }} />
            <img src="./images/ic_pause.png" style={(this.state.status === 1)?{ display: 'block' }:{ display: 'none' }} />
            <img src="./images/ic_play_selected.png" style={(this.state.status === 2)?{ display: 'block' }:{ display: 'none' }} />
          </a>

          <div style={this.state.selected?top_selected:top_unselected}>
            <div>
              <div className="col-md-8 col-sm-8 col-8 alert-title">
                <span style={this.state.selected?title_selected:{}}>Audio Message #{moment(this.props.audio.created_at).format('YY') + "." + this.numberFormat(this.props.audio.title_number)}</span>
              </div>
              <div className="col-md-4 col-sm-4 col-4 alert-time">
                <span>{moment.tz(this.props.audio.created_at, 'utc').tz(this.props.timezone).format("HH:mm MM.DD.YY")}</span>
              </div>
            </div>
            <div className="timeline_container" style={this.state.selected?timeline_show:timeline_hide}>
              <div className="timeline" ref="timeline">
                <div className="playhead" style={{marginLeft: this.state.playPercent + "%"}}></div>
                <div className="track" style={{width: this.state.track_width + "%" }}></div>
              </div>
              <span className="alert_progress">{this.state.progress} &nbsp;</span>
              <span className="remaining_time" style={{float: 'right'}}>{this.state.remaining} &nbsp;</span>
            </div>
          </div>

          <a className="btn-remove-message" style={this.state.selected?{color: "white"}:{}}
              onClick={this.deleteAudio}><i className="fa fa-trash"></i></a>
          {this.props.audio.type == 1 && 
          <img src="./images/ic_checked.png" class="audio-type" alt="" />
          }
          {this.props.audio.type == 0 && 
          <img src="./images/ic_warning.png" class="audio-type" alt="" />
          }
        </div>
      </div>
    )
  }
}

const audioplayer_selected = {
    padding: '12px 10px 3px 15px',
    background: '#41413d'
}

const timeline_show = {
    display: 'block'
}

const timeline_hide = {
    display: 'none'
}

const top_selected = {
    marginTop: '-10px'
}
const top_unselected = {
    marginTop: 0
}

const title_selected = {
    color: 'white'
}