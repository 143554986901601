import React, { Component } from 'react'
import QRCode from 'qrcode.react'
import axios from 'axios';
import Modal from 'react-bootstrap/Modal'; 
import NavDropdown from 'react-bootstrap/NavDropdown';
import config from '../config'

export default class DeviceTopNav extends Component {

  constructor(props) {
    super(props);
    this.state = {
        filter: 0,
        sort: 0,
        devices: [],
        isopenfilter: false,
        isopensortby: false,
        userId: 0,
        showQRModal: false
    };

    this.onFilter = this.onFilter.bind(this);
    this.onSortBy = this.onSortBy.bind(this);
    this.closeQRModal = this.closeQRModal.bind(this);
    this.showQRModal = this.showQRModal.bind(this);
    this.updateDevices = this.updateDevices.bind(this);
    this.getUserInfo = this.getUserInfo.bind(this);
  }

  componentDidMount() {
    this.getUserInfo();
  }

  onFilter(filter) {
    this.setState({filter: filter});
    this.props.onFilter(filter);
  }

  onSortBy(sort) {
    this.setState({sort: sort});
    this.props.onSortBy(sort);
  }

    closeQRModal() {
        this.setState({
            showQRModal: false
        })
    }

    showQRModal() {
        this.setState({
            showQRModal: true
        });
    }
  getUserInfo() {
    var self = this;
    var payload={
      auth_token: localStorage.getItem("silence_token")
    };

    axios.post(config.apiUrl + '/user/get', payload)
    .then(function (response) {              
      if (response.status === 200) {
        if (response.data.code === 1) {
          self.setState({
            userId: response.data.id
          });
          
        } else {
          localStorage.setItem("silence_token", "");
        }

      } else {
        alert("Server Error");
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  updateDevices(devices) {    
    this.setState({devices: devices});
  }

  render() {
    var view_filter = null;
    switch(this.state.filter) {
      case 0:
        view_filter = <span>View: <i className="fa fa-circle-o" style={{color:"#6b6b64"}}></i> All devices</span>;
        break;
      case 1:
        view_filter = <span>View: <i className="fa fa-circle" style={{color:'#20a920'}}></i> Online devices</span>;
        break;
      case 2:
        view_filter = <span>View: <i className="fa fa-circle" style={{color:'#d42928'}}></i> Offline devices</span>;
        break;  
      case 3:
        view_filter = <span>View: <i className="fa fa-circle-o" style={{color:'#d42928'}}></i> On trial</span>;
        break;
      case 4:
        view_filter = <span>View: <i className="fa fa-circle" style={{color:'#818178'}}></i> Expired licenses</span>;
        break;
    }

    //console.log(this.state.views, view_filter)

    var sortby_render = null;
    switch(this.state.sort) {
      case 0:
        sortby_render = <span>Sort by: <span> Device name </span></span>
        break;
      case 1:
        sortby_render = <span>Sort by: <span> Device status </span></span>
        break;
      case 2:
        sortby_render = <span>Sort by: <span> Number of audio messages </span></span>
        break;
    }

    var onlines = 0, offlines = 0, expired = 0, trials = 0;    
    var nUnsubscribes = 0;

    this.state.devices.forEach(device => {
      if (device.nSecondsFromSync <= 3600 && device.status === "Alive") {
        onlines++;
      } else {
        offlines++;
      }

      if (device.renew === 0) {
        nUnsubscribes++;
      }

      if (device.nDaysLeft <= 0) {
        expired++;
      }

      if (device.trial === 1) {
        trials++;
      }
    });  

    var click_here_to_purchases = "";
    if (nUnsubscribes > 0) {
      click_here_to_purchases = <div>{nUnsubscribes} device need subscriptions: <a href="subscription" style={{color:'red',cursor:'pointer'}}>click here to purchase</a></div>
    }
        
    return (
      <div className="device-top-nav">
        <div className="col-sm-5 col-md-5 col-12 overview">
          Showing {this.props.devices.length} devices (online: {onlines}, offline: {offlines}, expired: {expired})
          <br/>
          {click_here_to_purchases}
          
        </div>
            
            <div className="col-sm-2 col-md-2 col-12 qrcode">
                {/*}
                <QRCode onClick={this.showQRModal} value={config.baseUrl + "scan/all/" + this.state.userId} size={50} />
                <span style={{ width: 'calc(100% - 57px)', float: 'right', margin: '3px 0 0 0' }}>Subscribe to<br />all devices</span>
                {*/}
            </div>
            
        <div className="col-sm-5 col-md-5 col-12">
          <div className="row">
              <div className="col-sm-2 col-md-2 col-xs-6" style={{padding: 0}}>
                <a style={{display:'none'}}>Expand audio messages</a>
              </div>
              <div className="col-sm-5 col-md-5 col-6 device-filter">
                <div ref="dropdown_filter">
                     <NavDropdown title={view_filter} id="basic-nav-dropdown">
                        <NavDropdown.Item href="#" onClick={() => this.onFilter(0)}>
                          <i className="fa fa-circle-o" style={{color:'#6b6b64'}}></i> All devices
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#" onClick={() => this.onFilter(1)}>
                          <i className="fa fa-circle" style={{color:'#20a920'}}></i>  Online devices ({onlines})
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#" onClick={() => this.onFilter(2)}>
                          <i className="fa fa-circle" style={{color:'#d42928'}}></i> Offline devices ({offlines})
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#" onClick={() => this.onFilter(3)}>
                          <i className="fa fa-circle-o" style={{color:'#d42928'}}></i> On trial ({trials})
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#" onClick={() => this.onFilter(4)}>
                          <i className="fa fa-circle" style={{color:'#818178'}}></i> Expired licenses ({expired})
                        </NavDropdown.Item>
                        <i></i>
                      </NavDropdown>
                </div>
              </div>
              <div className="col-sm-5 col-md-5 col-6 device-filter" style={{padding: 0}}>
                        <NavDropdown title={sortby_render} id="basic-nav-dropdown" style={{width: 'auto'}}>
                  <NavDropdown.Item href="#" onClick={() => this.onSortBy(0)}>Device name</NavDropdown.Item>
                  <NavDropdown.Item href="#" onClick={() => this.onSortBy(1)}>Device status</NavDropdown.Item>
                  <NavDropdown.Item href="#" onClick={() => this.onSortBy(2)}>Number of audio messages</NavDropdown.Item>
                  <i></i>
                </NavDropdown>
              </div>
          </div>
        </div>
            <div className="clearfix"></div>

            <Modal show={this.state.showQRModal} onHide={this.closeQRModal} dialogClassName="showQRModal">
                <Modal.Header>
                    <Modal.Title>Subscribe to Device</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ textAlign: 'center' }}>
                        <QRCode value={config.baseUrl + "scan/" + this.state.userId} size={250} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-default btn-common" onClick={this.closeQRModal} style={{ margin: '0 auto', color: '#d42928' }}>Close</button>
                </Modal.Footer>
            </Modal>
      </div>
    )
  }
}
