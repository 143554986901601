import React, { Component } from 'react'
import QRCode from 'qrcode.react'
import moment from 'moment'
import Alert from 'react-s-alert';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import DatetimeRangePicker from './datetimerangepicker/index';
import AudioPlayer from '../components/AudioPlayer'
import config from '../config';

import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

export default class Device extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isrename: false,
            description: "",
            selected_audio: 0,
            start_date: null,
            end_date: null,
            audios: props.device.audios,
            nSubscribers: props.device.nSubscribers,
            nSecondsFromSync: props.device.nSecondsFromSync,
            status: props.device.status,
            showQRModal: false
        };

        this.getOnlineInfo = this.getOnlineInfo.bind(this);
        this.keyPress = this.keyPress.bind(this);
        this.onRemoveDevice = this.onRemoveDevice.bind(this);
        this.deviceRename = this.deviceRename.bind(this);
        this.handleApply = this.handleApply.bind(this);
        this.onSelected = this.onSelected.bind(this);
        this.onRemovedAudio = this.onRemovedAudio.bind(this);
        this.refreshMessages = this.refreshMessages.bind(this);
        this.closeQRModal = this.closeQRModal.bind(this);
        this.showQRModal = this.showQRModal.bind(this);
        this.url = "";
        this.audio = null;
    }

    closeQRModal() {
        this.setState({
            showQRModal: false
        })
    }

    showQRModal() {
        this.setState({
            showQRModal: true
        });
    }

    componentWillUnmount() {
        clearInterval(this.refreshTimer)
    }

    componentDidMount() {
        this.setState({ description: this.props.device.project_name })
        this.refreshTimer = setInterval(() => this.refreshMessages(), 5000);
    }

    componentWillReceiveProps(newProps) {
        this.setState({ selected_audio: 0 });
    }

    getOnlineInfo(nSecondsFromSync, status) {
        this.props.onGetOnline(this.props.device, nSecondsFromSync, status);
    }

    keyPress(e) {
        if (e.keyCode === 13) {
            this.deviceRename();
        }
    }

    deviceRename() {
        var self = this;

        if (this.state.description == "") {
            Alert.error("Cannot be empty!", {
                position: 'top-right',
                effect: 'slide',
                timeout: 3000
            });
        } else {
            var payload = {
                auth_token: localStorage.getItem("silence_token"),
                description: this.state.description,
                regId: this.props.device.project_key
            };


            axios.post(config.apiUrl + '/device/rename', payload)
                .then(function (response) {
                    if (response.status === 200) {
                        if (response.data.code === 1) {
                            self.setState({ isrename: false })

                        } else {
                            Alert.error(response.data.msg, {
                                position: 'top-right',
                                effect: 'slide',
                                timeout: 3000
                            });
                        }

                    } else {
                        alert("Server Error");
                    }
                }).catch(function (error) {
                    console.log(error);
                });
        }
    }

    onRemoveDevice() {
        this.props.onRemoveDevice(this.props.device);
    }

    handleApply(event, picker) {
        this.setState({
            start_date: picker.startDate,
            end_date: picker.endDate,
        });
    }

    onSelected(audio) {
        this.setState({
            selected_audio: audio.no
        })
    }

    onRemovedAudio(deletedAudio) {
        var audios = this.state.audios.filter(function (audio) {
            return deletedAudio.no !== audio.no
        })
        this.setState({ audios: audios })
    }

    refreshMessages() {
        var self = this;

        var payload = {
            auth_token: localStorage.getItem("silence_token"),
            project_key: this.props.device.project_key
        };

        axios.post(config.apiUrl + '/channel/refresh', payload)
            .then(function (response) {
                if (response.status === 200) {
                    if (response.data.code === 1) {

                        self.setState({
                            audios: response.data.device.audios,
                            nSubscribers: response.data.device.nSubscribers,
                            status: response.data.device.status,
                            nSecondsFromSync: response.data.device.nSecondsFromSync
                        })

                        self.getOnlineInfo(response.data.device.nSecondsFromSync, response.data.device.status);
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    render() {
        var isOnline = false;

        if (this.state.nSecondsFromSync <= 3600 && this.state.status === "Alive") {
            isOnline = true;
        }

        var audiosRender = [], audioNum = 0;

        this.state.audios.forEach(element => {
            if (this.state.start_date && this.state.end_date) {

                var start_date_str = moment(this.state.start_date).format("YYYY-MM-DD HH:mm:00");
                var end_date_str = moment(this.state.end_date).format("YYYY-MM-DD HH:mm:59");

                var startDate = moment.tz(start_date_str, this.props.device.timezone);
                var endDate = moment.tz(end_date_str, this.props.device.timezone);

                var createDate = moment.tz(element.created_at, 'utc');
                if (startDate > createDate || createDate > endDate) {
                    return;
                }
            }

            audioNum++;

            if (element.no == this.state.selected_audio) {
                audiosRender.push(<AudioPlayer timezone={this.props.device.timezone} num={audioNum} audio={element} key={element.no} selected_audio={element.no}
                    selected={true} onSelected={this.onSelected} onRemovedAudio={this.onRemovedAudio} />)
            } else {
                audiosRender.push(<AudioPlayer timezone={this.props.device.timezone} num={audioNum} audio={element} key={element.no} selected_audio={element.no}
                    selected={false} onSelected={this.onSelected} onRemovedAudio={this.onRemovedAudio} />)
            }
        });


        const { device } = this.props;

        var state = "";
        var subscription_info = "";

        if (device.nDaysLeft == null) {
            device.nDaysLeft = 0;
        }

        if (device.subscribed === 1 || device.nDaysLeft > 0) {
            if (isOnline) {
                state = <div className="state state-online">ONLINE</div>
            } else {
                state = <div className="state state-offline">OFFLINE</div>
            }
        } else{
            state = <div className="state state-expired">EXPIRED</div>
        }

        if (device.subscribed === 0 && device.nDaysLeft > 0) {
            subscription_info = <span>&nbsp;|&nbsp;<a class="trial" href="subscription">{device.nDaysLeft} Days Trial left!</a></span>;
        } else if (device.subscribed === 0 && device.nDaysLeft == 0) {            
            subscription_info = <span>&nbsp;|&nbsp;<a class="trial" href="subscription">Subscribe your device!</a></span>;
        }
        

        return (
            <div className="device-item" style={this.props.index % 3 === 0 ? { marginRight: '0px' } : {}}>
                <div className="device-info">
                    <div onClick={this.showQRModal} style={{height: 'auto',width: 'auto',float: 'left', margin: '0'}}>
                        <QRCode value={config.baseUrl + "scan/" + this.props.device.project_key} size={53} className="qr-code" />
                    </div>
                    <div>
                        <div>
                            <div className="left">
                                <div className="device-name">
                                    {this.state.isrename ?
                                        <input type="text" value={this.state.description} style={{ height: '20px', width: 'calc(100% - 20px)' }} onKeyDown={this.keyPress} onChange={(event) => this.setState({ description: event.target.value })}/>:
                                        this.state.description}
                                    {this.state.isrename &&
                                        <div onClick={this.deviceRename} class="rename-submit"></div>}
                                </div>
                                <div className="device-id">{this.props.device.project_key}</div>
                            </div>
                            <div className="right">
                                {state}
                            </div>
                        </div>
                    </div>
                    <div className="device-action">
                        <a onClick={() => this.setState({ isrename: true })}>Rename device</a>
                        <span>&nbsp;|&nbsp;</span>
                        <a onClick={this.onRemoveDevice}>Remove device</a>
                        {subscription_info}

                    </div>
                </div>

                <div className="search-header">
                    <span>Audio Messages ({audioNum})</span>
                    <div>
                        <span>Range: </span>
                        <DatetimeRangePicker
                            timePicker
                            timePicker24Hour
                            showDropdowns
                            timePickerSeconds
                            startDate={this.state.start_date ? this.state.start_date : moment()}
                            endDate={this.state.end_date ? this.state.end_date : moment()}
                            onApply={this.handleApply}
                        >
                            <div className="date-range" id={"audio-range-time-" + this.props.device.no} style={this.state.start_date ? { lineHeight: '17px' } : { lineHeight: '32px' }}>
                                {!this.state.start_date && "Showing all messages"}
                                {this.state.start_date && <div><div style={{ fontWeight: 'bold' }}>{moment(this.state.start_date).format('MM/DD/YY')}</div><div>{moment(this.state.start_date).format('HH:mm')}</div></div>}
                                {this.state.start_date && <div className="mt-10">&nbsp;&nbsp;to&nbsp;&nbsp;</div>}
                                {this.state.start_date && <div><div style={{ fontWeight: 'bold' }}>{moment(this.state.end_date).format('MM/DD/YY')}</div><div>{moment(this.state.end_date).format('HH:mm')}</div></div>}
                            </div>
                        </DatetimeRangePicker>

                    </div>
                </div>
                <div className="alerts_list scrollbar style-1" style={{ paddingRight: '0px' }}>
                    {audiosRender}
                </div>
                <div className="subscribe-info">
                    <span>Users subscribed: {this.state.nSubscribers}</span>
                    <a onClick={() => this.props.onInvite(this.props.device)}>Send email invitations</a>
                </div>

                <Modal show={this.state.showQRModal} onHide={this.closeQRModal} dialogClassName="showQRModal">
                    <Modal.Header>
                        <Modal.Title>Subscribe to Device</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ textAlign: 'center' }}>
                            <QRCode value={config.baseUrl + "scan/" + this.props.device.project_key} size={250}/>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-default btn-common" onClick={this.closeQRModal} style={{ margin: '0 auto', color: '#d42928'}}>Close</button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}