import React, { Component } from 'react'

export default class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: props.editMode
    };
    this.editClicked = this.editClicked.bind(this);
  }

  editClicked() {
    this.props.onEditClicked(this.props.user);
  }

  render() {
    return (
      <tr className={this.props.editMode?"checked-user-tr":""}>
        <td>{this.props.user.username}</td>
        <td>{this.props.user.email}</td>
        <td>{this.props.user.company}</td>
        <td>{this.props.user.nSubscribers} / {(this.props.user.subscriber_limit)?this.props.user.subscriber_limit:"∞"}</td>
        <td>
            {(this.props.user.is_admin === 1)?<i className="fa fa-user-plus" style={{color: '#818178'}}></i>:""}
        </td>
        <td>
            {(this.props.user.activate===1)?<i className="fa fa-circle" style={{color: '#ff7538'}}></i>:<i className="fa fa-circle" style={{color: '#959595'}}></i>}
        </td>
        <td className="td-edit">
          <a onClick={(event) => this.editClicked()}>
            <i className="fa fa-edit" style={{color:'#d42928',fontSize:'18px'}}></i>
          </a>
        </td>
      </tr>
    )
  }
}
