import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import axios from 'axios';
import Alert from 'react-s-alert';
import Modal from 'react-bootstrap/Modal';
import config from '../config'
import TopNav from '../components/TopNav'
import DeviceTopNav from '../components/DeviceTopNav'
import Device from '../components/Device'
import TimezonePicker from 'react-bootstrap-timezone-picker';

import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import { throwStatement } from '@babel/types';

export default class Devices extends Component {
  constructor(props) {
    super(props);
    this.state = {
        regId: "",
        description: "",
        timezone: "",
        devices: [],
        filter: 0,
        sort_by: 0,
        filter_name: '',
        delete_device: null,
        invite_device: null,
        email_invitations: 1,
        showModal: false,
        showAddDeviceModal: false,
        showInviteModal: false,
        login_page: false,
    };
    
      this.getDeviceList = this.getDeviceList.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleInviteSubmit = this.handleInviteSubmit.bind(this);
      this.onGetOnline = this.onGetOnline.bind(this);
      this.onRemoveDevice = this.onRemoveDevice.bind(this);
      this.deleteDevice = this.deleteDevice.bind(this);
      this.closeConfirmModal = this.closeConfirmModal.bind(this);
      this.closeAddDeviceModal = this.closeAddDeviceModal.bind(this);
      this.closeInviteModal = this.closeInviteModal.bind(this);
      this.onFilter = this.onFilter.bind(this);
      this.onSortBy = this.onSortBy.bind(this);
      this.onFilterChange = this.onFilterChange.bind(this);
      this.onInvite = this.onInvite.bind(this);
      this.handleTimeZoneChange = this.handleTimeZoneChange.bind(this);
      this.renderDevices = this.renderDevices.bind(this);
      this.renderLoginPage = this.renderLoginPage.bind(this);
  }

  componentDidMount() {
    this.getDeviceList();
  }

  onFilterChange(event) {
    this.setState({filter_name: event.target.value})
  }

  getDeviceList() {
    var self = this;
    var payload = {
        auth_token: localStorage.getItem("silence_token")
    };

    axios.post(config.apiUrl + '/device/get', payload)
    .then(function (response) {
      if (response.status === 200) {
        if (response.data.code === 1) {
          self.setState({ devices: response.data.devices })
          self.refs.deviceTopNav.updateDevices(self.state.devices);

        } else {
          localStorage.setItem("silence_token", "");
          self.setState({login_page: true});
        }

      } else {
        alert("Server Error");
      }
    })
    .catch(function (error) {
        console.log(error);
    });
  }



  handleSubmit(event) {
    event.preventDefault();
    
    if (!this.state.timezone) {
      Alert.error("Please choose the device timezone.", {
        position: 'top-right',
        effect: 'slide',
        timeout: 3000
      });
      return;
    }

    var self = this;
    const payload = {
      regId: this.state.regId,
      description: this.state.description,
      auth_token: localStorage.getItem("silence_token"),
      timezone: this.state.timezone,
      generic: 0
    }

    this.closeAddDeviceModal();

    axios.post(config.apiUrl + '/device/create', payload)
    .then(function (response) {

      if (response.status === 200) {
        if (response.data.code === 1) {
          var devices = self.state.devices;
          devices.push(response.data.device);
          self.setState({ devices: devices, regId: "", description: "" });
          
        } else {
          Alert.error(response.data.msg, {
            position: 'top-right',
            effect: 'slide',
            timeout: 3000
          });
        }

      } else {
        alert("Server Error");
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  handleInviteSubmit(event) {
    event.preventDefault();

    var emails = [];
    for(var i = 0; i < this.state.email_invitations; i++) {
      var email = this.refs["email" + i].value;
      if (email) {
        emails.push(email);
      }
    }

    if (emails.length === 0) {
      return;
    }

    var payload = {
      auth_token: localStorage.getItem("silence_token"),
      emails: JSON.stringify(emails),
      regId: this.state.invite_device.project_key
    };

    this.closeInviteModal();

    axios.post(config.apiUrl + '/device/invitation', payload)
    .then(function (response) {
      if (response.status === 200) {        
        if (response.data.code === 1) {
          Alert.success(response.data.msg, {
            position: 'top-right',
            effect: 'slide',
            timeout: 3000
          });

        } else {
          Alert.error(response.data.msg, {
            position: 'top-right',
            effect: 'slide',
            timeout: 3000
          });
        }
      
      } else {
        console.log("Server Error");
      }
    })
    .catch(function (error) {
      console.log(error);
    }); 
  }

  onGetOnline(device, nSecondsFromSync, status) {
    //console.log(bOnline)
    var devices = this.state.devices;
    devices.forEach(element => {
      if (element.project_key === device.project_key) {
        element.nSecondsFromSync = nSecondsFromSync;
        element.status = status;
      }
    });

    this.refs.deviceTopNav.updateDevices(devices);
  }

  onRemoveDevice(device) {
    this.setState({
      showModal: true,
      delete_device: device
    })
  }

  closeConfirmModal() {
    this.setState({
      showModal: false
    })
    }

  closeAddDeviceModal() {
    this.setState({
      showAddDeviceModal: false
    })
  }

  closeInviteModal() {
    this.setState({
      showInviteModal: false
    })
  }

  deleteDevice() {
    var self = this;
    var payload = {
      auth_token: localStorage.getItem("silence_token"),
      regId: this.state.delete_device.project_key
    };

    this.closeConfirmModal();

    axios.post(config.apiUrl + '/device/delete', payload)
    .then(function (response) {
      //console.log(response)

      if (response.status === 200) {
        if (response.data.code === 1) {

          var devices = [];
          self.state.devices.forEach(element => {
            if (element.project_key !== self.state.delete_device.project_key) {
              devices.push(element);
            }
          });
          
          self.setState({devices: devices});
          self.refs.deviceTopNav.updateDevices(self.state.devices);

        } else {
          Alert.error(response.data.msg, {
            position: 'top-right',
            effect: 'slide',
            timeout: 3000
          });
        }

      } else {
        alert("Server Error");
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  handleTimeZoneChange(newValue) {
    this.setState({
      timezone: newValue
    })
  }

  onFilter(filter) {
    this.setState({filter: filter})
  }

  onSortBy(sort_by) {
    this.setState({sort_by: sort_by})
  }

  onInvite(device) {
    this.setState({
      invite_device: device,
      showInviteModal: true
    })
  }

  renderDevices() {
    var devices = [];
    this.state.devices.forEach(element => {
      if (element.project_name.indexOf(this.state.filter_name) !== -1 ||
          element.project_key.indexOf(this.state.filter_name) !== -1) {

        if (this.state.filter === 0) {
          devices.push(element);

        } else if (this.state.filter === 1) {
          if (element.nSecondsFromSync <= 3600 && element.status === "Alive") {
            devices.push(element);
          }

        } else if (this.state.filter === 2) {
          if (element.nSecondsFromSync > 3600 || element.status !== "Alive") {
            devices.push(element);
          }

        } else if (this.state.filter === 3) {
          if (element.trial === 1) {
            devices.push(element);
          }

        } else if (this.state.filter === 4) {
          if (element.nDaysLeft <= 0) {
            devices.push(element);
          }
        }
      }
    });

    for (var i = 0; i < devices.length; i++) {
      var online1 = 0;
      if (devices[i].nSecondsFromSync <= 3600 && devices[i].status === "Alive") {
        online1 = 1;
      } else {
        online1 = 0;
      }
        
      for (var j = i + 1; j < devices.length; j++) {
          
        if (this.state.sort_by === 0) {
          if (devices[i].project_name > devices[j].project_name) {
            var c = devices[i];
            devices[i] = devices[j];
            devices[j] = c;
          }
        }

        if (this.state.sort_by === 1) {
          var online2 = 0;
          if (devices[j].nSecondsFromSync <= 3600 && devices[j].status === "Alive") {
            online2 = 1;
          } else {
            online2 = 0;
          }

          if (online1 < online2) {
            var c = devices[i];
            devices[i] = devices[j];
            devices[j] = c;
          }
        }
          

        if (this.state.sort_by === 2) {
          if (devices[i].audios.length < devices[j].audios.length) {
            var c = devices[i];
            devices[i] = devices[j];
            devices[j] = c;
          }
        }
      }
    }        
    
    var i = 0;
    var devicesRender = devices.map((item, key) => {
      i++;
      return <Device device={item} key={key} onGetOnline={this.onGetOnline} onRemoveDevice={this.onRemoveDevice} 
                onInvite={this.onInvite} index={i} />
    });

    return devicesRender;
  }

  renderLoginPage() {
    if (this.state.login_page) {
      return <Redirect to="/login" />
    }
  }

  render() {
    var emailFields = [];
    for (var i = 0; i < this.state.email_invitations; i++) {
      emailFields.push(<input type="email" ref={"email" + i} className="form-control mt-10" 
              placeholder="Enter email address to send invitation" key={i} />)
    }

    return (
      <div>
        <div className="container body">
          <div className="main_container">
            <TopNav location="devices" />
                
            <div className="main_contents" role="main">
                <div className="x_panel">
                    <div className="x_title row block">
                        <div className="col-md-4 col-sm-4 col-4">
                            <h5>Manage Devices</h5>
                        </div>
                        <div className="col-md-4 col-sm-4 col-2" style={{ paddingTop: '9px' }}>
                            <div className="inner-addon right-addon search-box">
                                <i className="fa fa-search" style={{right:'0px'}}></i>
                                <input type="text" placeholder="Search for device name or ID" className="form-control" style={{width: '100%'}}
                                    onChange={this.onFilterChange} />
                            </div>  
                        </div>
                        <div className="nav navbar-right panel_toolbox">
                            <button className="btn btn-default btn-create-channel" onClick={() => this.setState({showAddDeviceModal: true})}>
                                <i className="fa fa-plus"></i> &nbsp;&nbsp;&nbsp;Add Device
                            </button>  
                        </div>
                        <div className="clearfix"></div>
                    </div>
                    
                    <DeviceTopNav ref="deviceTopNav" devices={this.state.devices} onFilter={this.onFilter} onSortBy={this.onSortBy} />
                    
                    <div className="mb-30"></div>
                    <div className="row">
                        {this.renderDevices()}
                    </div>
                </div>
            </div>
          </div>
        </div>

        <Modal show={this.state.showModal} onHide={this.closeConfirmModal} dialogClassName="ConfirmModal">
          <Modal.Header>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              Are you sure you want to delete the device named {this.state.delete_device?this.state.delete_device.project_name:""}? <br />
              This action cannot be undone.
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-default btn-cancel" onClick={this.closeConfirmModal}>Cancel</button>
            <button type="button" className="btn btn-primary btn-yes" onClick={this.deleteDevice}>Yes</button>
          </Modal.Footer>
            </Modal>

        <Modal show={this.state.showAddDeviceModal} onHide={this.closeAddDeviceModal} dialogClassName="AddDeviceDialog">
          <Modal.Header>
            Add/Edit Device
            <span className="information_mark tooltip z-index-10006">
              <i className="fa fa-info info"></i>
              <div className="bottom">
                <h5>Add device</h5>
                <p>Add as many devices as needed or start with one and add more later.</p>
                <i></i>
              </div>
            </span>
          </Modal.Header>
          <div id="register" className="animate login_form ">
            <section className="login_content">
              <form method="post" onSubmit={this.handleSubmit}>
                <div className="row pl-20">
                  <div className="col-md-4 col-sm-4 col-xs-12">
                    <img src="./images/device_icon.png" width="140" style={{float:'left',marginTop:'30px'}} />
                  </div>
                  <div className="col-md-8 col-sm-8 col-xs-12">
                    <div>
                      <label htmlFor="registration_id" className="w-100">&nbsp;</label>
                      <input type="text" className="form-control mark_field" name="reg_id" id="reg_id" placeholder="Registration ID" required
                          value={this.state.regId} onChange={(event) => this.setState({regId:event.target.value})} />
                      <span className="tooltip z-index-10005">
                        <i className="fa fa-info info"></i>
                        <div className="bottom">
                          <h5>Registration ID</h5>
                          <p>Find this number on the bottom of your device.</p>
                          <i></i>
                        </div>
                      </span>
                    </div>
                    <div>
                      <label htmlFor="description" className="w-100">&nbsp;</label>
                      <input type="text" className="form-control mark_field" name="description" id="description" placeholder="Device name" 
                          value={this.state.description} onChange={(event) => this.setState({description:event.target.value})} />
                      <span className="tooltip z-index-10004">
                        <i className="fa fa-info info"></i>
                        <div className="bottom">
                          <h5>Description (Optional)</h5>
                          <p>Give your device a name to better recognize it later. <br/> ex. Talk Station 1. Floor.</p>
                          <i></i>
                        </div>
                      </span>
                    </div>
                    <div>
                      <label htmlFor="description" className="w-100">&nbsp;</label>
                      <TimezonePicker
                        absolute      = {false}
                        placeholder   = "Select device timezone..."
                        className="mark_field"
                        onChange      = {this.handleTimeZoneChange}
                      />
                      <span className="tooltip z-index-10004">
                        <i className="fa fa-info info"></i>
                        <div className="bottom">
                          <h5>Device timezone</h5>
                          <p>Give your device a name to better recognize it later. <br/> ex. Talk Station 1. Floor.</p>
                          <i></i>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
                  
                <div className="mt-20 separator">
                  <div className="row block" style={{padding:'15px 10px 0px 30px'}}>
                    <a data-dismiss="modal" className="to_register" style={{lineHeight:'39px'}} onClick={this.closeAddDeviceModal}>Cancel</a>
                    <div className="next_step pull-right" style={{marginRight:'25px'}}>
                      <button type="submit" className="btn-login btn-next-step mt-0 submit">Add Device</button>
                      <input type="hidden" id="selected_channels" name="selected_channels" />
                      <input type="hidden" id="device_no" name="device_no" />
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </form>
            </section>
          </div>
            </Modal>

        <Modal show={this.state.showInviteModal} onHide={this.closeInviteModal} dialogClassName="SendInvitationDialog">
          <Modal.Header>Send Email Invitations</Modal.Header>
          <div id="register" className="login_form ">
            <form method="post" onSubmit={this.handleInviteSubmit}>
              <div className="row">
                <div className="col-sm-1 col-md-1 col-xs-1"></div>
                <div className="col-sm-10 col-md-10 col-xs-10">
                  { emailFields }
                  <div className="mt-20">
                    <a onClick={() => this.setState({email_invitations: this.state.email_invitations + 1})}>Add another email field</a>
                  </div>
                </div>
              </div>
              
              <div className="mt-20 separator">
                <div className="row block" style={{padding:'15px 10px 0px 30px'}}>
                  <a data-dismiss="modal" style={{lineHeight:'39px'}} onClick={this.closeInviteModal}>Cancel</a>
                  <div className="next_step pull-right" style={{marginRight:'25px'}}>
                    <button type="submit" className="btn-login btn-next-step mt-0 submit">Send Invitations ({this.state.email_invitations})</button>
                    <input type="hidden" id="selected_channels" name="selected_channels" />
                    <input type="hidden" id="device_no" name="device_no" />
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </form>                            
          </div>
        </Modal>
        
        
        <Alert stack={{limit: 1}} />
    </div>
    )
  }
}
