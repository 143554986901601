import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import axios from 'axios';
import Alert from 'react-s-alert';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Modal from 'react-bootstrap/Modal';
import config from '../config'
import TopNav from '../components/TopNav'
import SubscriptionItem from '../components/SubscriptionItem'
import UnsubscribeItem from '../components/UnsubscribeItem'

export default class Subscription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subscribed_devices: [],
            unsubscribed_devices: [],
            is_subscription_selected_all: false,
            is_ubsubscribe_selected_all: false,
            isopenfilter: false,
            unsubscriberfilter: 0,
            filter_name: '',
            selectionsForSubscription: {},
            selectedForSub: [],
            selectedForUnSub: [],
            selectionsForUnsubscription: {},
            showModal: false,
            showUnSubModal: false,
            showCouponModal: false,
            couponCode: null,
            login_page: false,
            totalCost: 0
        };

        // Vital Funcitons
        this.renderLoginPage = this.renderLoginPage.bind(this);
        this.getDeviceList = this.getDeviceList.bind(this);
        // Subscribe Functions
        this.SUB_openModal = this.SUB_openModal.bind(this);
        this.SUB_proceed = this.SUB_proceed.bind(this);
        this.SUB_onChange = this.SUB_onChange.bind(this);
        // Unsubscribe Functions
        this.UNSUB_onChange = this.UNSUB_onChange.bind(this);
        this.UNSUB_proceed = this.UNSUB_proceed.bind(this);
        this.UNSUB_openModal = this.UNSUB_openModal.bind(this);
        // Modals
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.openCouponModal = this.openCouponModal.bind(this);
        this.closeCouponModal = this.closeCouponModal.bind(this);
        this.closeUnSubModal = this.closeUnSubModal.bind(this);
        //Coupons
        this.addCoupon = this.addCoupon.bind(this);
        
    }
/* Vital Functions */
    componentDidMount() {
        this.getDeviceList();
    }
    renderLoginPage() {
        if (this.state.login_page) {
            return <Redirect to="/login" />
        }
    }
    getDeviceList() {
        var self = this;
        var payload = {
            auth_token: localStorage.getItem("silence_token")
        };

        axios.post(config.apiUrl + '/device/get', payload).then(function (response) {
            if (response.status === 200) {
                if (response.data.code === 1) {
                    var subscribed_devices = [], unsubscribed_devices = [];
                    response.data.devices.forEach(device => {

                        if (device.subscribed === 1) {
                            subscribed_devices.push(device);
                        } else {
                            unsubscribed_devices.push(device);
                        }
                    });
                    self.setState({
                        unsubscribed_devices: unsubscribed_devices,
                        subscribed_devices: subscribed_devices
                    })

                } else {
                    localStorage.setItem("silence_token", "");
                    self.props.history.push('/login');
                }
            } else {
                alert("Server Error");
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

/* Functions for Subscription list */
    SUB_onChange(device, bSelected) {
        if (this.state.selectedForSub.indexOf(device) != -1) {
            this.state.selectedForSub.splice(device, 1);
        } else {
            this.state.selectedForSub.push(device);
        }
        this.setState({
            totalCost: this.state.selectedForSub.length * config.licenseCost
        });
    }
    SUB_openModal(event) {
        if (this.state.selectedForSub.length != 0) {
            this.setState({ showModal: true });
        } else {
            return;
        }
    }
    SUB_proceed() {
        this.setState({ showModal: false });

        var RIDs = [];
        this.state.selectedForSub.forEach(RID => {
            RIDs.push(RID.project_key);
        });

        if (RIDs.length === 0) {
            return;
        }

        var payload = {
            auth_token: localStorage.getItem("silence_token"),
            devices: JSON.stringify(RIDs),
            coupon: this.state.couponCode
        };

        axios.post(config.apiUrl + '/devices/firstsubscribe', payload).then(function (response) {
            if (response.status === 200) {
                if (response.data.code === 1) {
                    window.location = response.data.url;
                } else {
                    Alert.error(response.data.msg, {
                        position: 'top-right',
                        effect: 'slide',
                        timeout: 3000
                    });
                }
            } else {
                console.log("Server Error");
            }
        }).catch(function (error) {
            console.log(error);
        });
    }


/* Functions for UnSubscription List */
    UNSUB_onChange(device, bSelected) {
        if (this.state.selectedForUnSub.indexOf(device) != -1) {
            this.state.selectedForUnSub.splice(device, 1);
        } else {
            this.state.selectedForUnSub.push(device);
        }
    }
    UNSUB_openModal() {
        if (this.state.selectedForUnSub.length != 0) {
            this.setState({ showUnSubModal: true });
        } else {
            return;
        }
    }
    UNSUB_proceed() {
        this.setState({ showUnSubModal: false });

        var RIDs = [];
        this.state.selectedForUnSub.forEach(RID => {
            RIDs.push(RID.project_key);
        });

        if (RIDs.length === 0) {
            return;
        }

        var payload = {
            auth_token: localStorage.getItem("silence_token"),
            devices: JSON.stringify(RIDs)
        };

        axios.post(config.apiUrl + '/devices/unsubscribe', payload).then(function (response) {
            if (response.status === 200) {
                if (response.data.code === 1) {
                    window.location.reload()
                } else {
                    Alert.error(response.data.msg, {
                        position: 'top-right',
                        effect: 'slide',
                        timeout: 3000
                    });
                }
            } else {
                console.log("Server Error");
            }
        }).catch(function (error) {
            console.log(error);
        });
    }


/* Handle Modals */
    handleCloseModal() {
        this.setState({
            showModal: false
        });
    }
    openCouponModal() {
        this.setState({ showCouponModal: true })
        console.log("Voucher Code: " + this.state.couponCode);
    }
    closeCouponModal() {
        this.setState({ couponCode: null });
        this.setState({ showCouponModal: false });
    }
    closeUnSubModal() {
        this.setState({ showUnSubModal: false });
    }


    /* Coupon functions - Currently disabled */
    addCoupon() {
        console.log("Voucher Code: " + this.state.couponCode);
        this.setState({ showCouponModal: false });
    }
    
    render() {
        return(
            <Redirect to="/" />
        )
    }
}
