import React, { Component } from 'react'
import axios from 'axios';
import config from '../config'
import Alert from 'react-s-alert';
import Switch from 'react-switchery';

import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';

export default class UserDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      is_admin: (props.user.is_admin===1?true:false),
      activate: (props.user.activate===1?true:false),
      username: props.user.username,
      company: props.user.company,
      email: props.user.email,
      subscriber_limit: props.user.subscriber_limit,
      password: "",
      logo: null,
      logoFile: props.user.company_logo?(config.baseUrl + props.user.company_logo):"./images/company_logo_placeholder.png"
    };

    this.cancelClicked = this.cancelClicked.bind(this);
    this.deleteClicked = this.deleteClicked.bind(this);
    this.updateUser = this.updateUser.bind(this);
  }

  updateUser() {
    var self = this;

    if (!this.state.username) {
      Alert.error("Enter username", {
        position: 'top-right',
        effect: 'slide',
        timeout: 3000
      });
      return;
    }

    if (!this.state.company) {
      Alert.error("Enter company name", {
        position: 'top-right',
        effect: 'slide',
        timeout: 3000
      });
      return;
    }

    if (!this.state.email) {
      Alert.error("Enter email address", {
        position: 'top-right',
        effect: 'slide',
        timeout: 3000
      });
      return;
    }

    if (!this.state.email) {
      Alert.error("Enter email address", {
        position: 'top-right',
        effect: 'slide',
        timeout: 3000
      });
      return;
    }

    const formData = new FormData();
    formData.append('auth_token', localStorage.getItem("silence_token"))
    formData.append('is_admin', (this.state.is_admin?1:0))
    formData.append('activate', (this.state.activate?1:0))
    formData.append('username', this.state.username)
    formData.append('company', this.state.company)
    formData.append('email', this.state.email)
    formData.append('subscriber_limit', (this.state.subscriber_limit?this.state.subscriber_limit:0))
    formData.append('password', this.state.password)
    formData.append('userid', this.props.user.id)
    formData.append('logo', this.state.logo)

    const upload_config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }

    axios.post(config.apiUrl + '/user/update', formData, upload_config)
    .then(function (response) {
      if (response.status === 200) {   
        if (response.data.code === 1) {
          self.props.onUpdatedUser(response.data.users);

        } else {
          Alert.error(response.data.msg, {
            position: 'top-right',
            effect: 'slide',
            timeout: 3000
          });
          return;
        }

      } else {
        alert("Server Error");
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  onChangeFile(event) {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    this.setState({
        logoFile: URL.createObjectURL(file),
        logo: file
    });
  }

  cancelClicked() {
    this.props.onCancelClicked(this.props.user);
  }

  deleteClicked() {        
    this.props.onDeleteClicked(this.props.user);
  }

  render() {
    return (
      <tr style={{background:'#ececdc'}}>
        <td colSpan="7">
          <form>
            <div className="row">
              <div className="col-sm-12 col-12 col-md-12">
                <Switch 
                  className="switch-class" 
                  onChange={() => this.setState({activate: !this.state.activate})}
                  options = {
                    {
                      color: '#d42928',
                      secondaryColor: '#818178',
                      size: 'small'
                    }
                  }
                  checked={this.state.activate}
                  />
                <label style={{color: (this.state.activate?'#d42928':'#959595'), margin:'3px 6px'}}>&nbsp;Activate</label> 
                <div className="pull-right">
                  <a className="btn-discard" onClick={this.cancelClicked}>Cancel</a>&nbsp;|&nbsp;
                  <a className="btn-save" onClick={this.updateUser }>Save changes</a>
                </div>
              </div>
              <div className="col-sm-12 col-12 col-md-12" style={{marginTop:'3px'}}>
                <Switch 
                  className="switch-class" 
                  onChange={() => this.setState({is_admin: !this.state.is_admin})}
                  options = {
                    {
                      color: '#d42928',
                      secondaryColor: '#818178',
                      size: 'small'
                    }
                  }
                  checked={this.state.is_admin}
                  />
                <label style={{color: (this.state.is_admin?'#d42928':'#959595'), margin:'3px 6px'}}>&nbsp;Admin</label>
              </div>
            </div>
                    
            <div className="row" style={{marginTop:'20px'}}>
              <div className="col-sm-5 col-5 col-md-5">
                <label className="form-label">Name</label>
                <input type="text" className="form-control" style={{width:'100%',borderRadius:'5px',height:'31px'}} value={this.state.username} required
                    onChange={(event) => this.setState({username:event.target.value})} />
              </div>
              <div className="col-sm-3 col-3 col-md-3">
                <label className="form-label">Company name</label>
                <input type="text" className="form-control" style={{width:'100%',borderRadius:'5px',height:'31px'}} value={this.state.company} required
                    onChange={(event) => this.setState({company:event.target.value})} />
              </div>
              <div className="col-sm-4 col-4 col-md-4">
                <div className="row">
                  <div className="col-sm-4 col-4 col-md-4 ta-r" style={{paddingTop:'33px'}}>
                    <span style={{lineHeight:'30px'}}>{this.props.user.nSubscribers}/</span> 
                  </div>
                  <div className="col-sm-8 col-8 col-md-8">
                    <label className="form-label">Subscriber limit</label>
                    <input type="number" className="form-control" style={{height:'31px'}} min="0" value={this.state.subscriber_limit?this.state.subscriber_limit:0}
                        onChange={(event) => this.setState({subscriber_limit:event.target.value})} />
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{marginBottom:'30px'}}>
              <div className="col-sm-5 col-5 col-md-5">
                <div className="row" style={{marginRight:'-10px',marginLeft:'-10px'}}>
                  <div className="col-sm-12 col-12 col-md-12">
                    <label className="form-label">Email address</label>
                    <input type="text" className="form-control" style={{width:'100%',borderRadius:'5px',height:'31px'}} value={this.state.email} required
                        onChange={(event) => this.setState({email:event.target.value})} />
                  </div>
                  <div className="col-sm-12 col-12 col-md-12">
                    <label className="form-label">Change password</label>
                    <input type="password" className="form-control" style={{width:'100%',borderRadius:'5px',height:'31px'}}
                        onChange={(event) => this.setState({password:event.target.value})} />
                  </div>
                </div>
              </div>
              <div className="col-sm-7 col-7 col-md-7">
                <img src={this.state.logoFile} width="110" height="110" alt="#" style={{marginTop:'23px',borderRadius:'100px'}}
                    onClick={()=>this.refs.fileUploader.click()} />
                <input type="file" ref="fileUploader" style={{display:'none'}} onChange={this.onChangeFile.bind(this)} />

                <div className="pull-right" style={{marginTop:'110px'}}>
                  <a className="btn-delete-user" style={{color:'#d42928',cursor:'pointer'}}
                      onClick={ (event) => this.deleteClicked() }>Delete user</a>
                </div>
              </div>
            </div>
          </form>
        </td>
      </tr>
    )
  }
}
