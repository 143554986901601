import React, { Component } from 'react'
import Modal from 'react-bootstrap/Modal';
import TopNav from '../components/TopNav'
import HelpItem from '../components/HelpItem';

export default class Help extends Component {
  constructor(props) {
    super(props);
    this.state = {
        showModal: false,
        showInstructionModal: false
    };

    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleInstructionModalClose = this.handleInstructionModalClose.bind(this);
  }

  handleModalClose() {
    this.setState({showModal: false})
  }

  handleInstructionModalClose() {
    this.setState({showInstructionModal: false})
  }

  render() {
    return (
      <div className="container body">
        <div className="main_container">

          <TopNav location="help" />

          <div className="main_contents ta-c" role="main">
            <div className="x_panel half bg-white">
              <div className="x_title row mb-0">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <h5>Help Center</h5>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <div className="help-block">
                    How to set up your device?<br/>
                    <div className="row">
                      <div className="col-5">
                        <img src="./images/device_icon.png" width="90%" />
                      </div>
                      <div className="col-7">
                        <span>M400</span><br/>
                        <span className="desc">The M400 is lorem ipsum dolor sit amet.</span>
                      </div>
                    </div>
                    <div className="link">
                      <a onClick={() => this.setState({showInstructionModal: true})} >Instructions for the M400 <i className="fa fa-angle-right"></i> </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12">
                  <div className="help-block">
                    Contact support<br/>
                    <div className="row">
                      <span className="desc">This portal is part of the Paging Cloud solution by MOH Technology. We are happy to answer your questions and improve it according to your needs.</span>
                    </div>
                    <div className="link">
                      <a onClick={() => this.setState({showModal: true})}> Send us a message </a> <br/>
                      <a href="mailto:support@paging.cloud"> support@paging.cloud </a> <br/>
                      <a href="http://moh-technology.com/paging-cloud" target="_blank"> moh-technology.com/paging-cloud </a> <br/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row ta-l mb-20">
                <div className="col-md-12 col-xs-12 col-sm-12 fre-questions">
                  Frequently Asked Questions
                </div>
                <HelpItem />
                <HelpItem />
                <HelpItem />
                <HelpItem />
                <HelpItem />
                <HelpItem />
                <HelpItem />
                <HelpItem />
                <HelpItem />
                <HelpItem />
                <HelpItem />
                <HelpItem />
              </div>
            </div>
          </div> 
        </div>

        <Modal show={this.state.showModal} onHide={this.handleModalClose} dialogClassName="support-modal">
          <Modal.Header>
            <Modal.Title>Contact Support</Modal.Title>
          </Modal.Header>
          <div className="animate login_form">
            <section className="login_content">
                <form method="post">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-12" style={{color:'black'}}>  
                            Got a question? Having trouble installing your devices? 
                            Send us a message and we'll respond as soon as possible.
                        </div>
                    </div>
                    <div className="row mt-20">
                        <div className="col-md-12 col-sm-12 col-12">  
                            <label>&nbsp;</label>
                            <input type="text" className="form-control" placeholder="Subject" />
                        </div>
                        <div className="col-md-12 col-sm-12 col-12">  
                            <label>&nbsp;</label>
                            <textarea className="form-control" style={{height:'150px',resize:'none'}} placeholder="Write your message"></textarea>
                        </div>
                    </div>
                    <div className="row mt-20">
                        <div className="col-sm-5 col-md-5 col-5">
                            <p style={{marginTop:'20px',color:'black'}}>
                                <a onClick={this.handleModalClose}><span style={{color:'#d42927'}}>Cancel</span></a>
                            </p>
                        </div>
                        <div className="col-sm-7 col-md-7 col-7">
                            <button type="submit" className="btn-login btn-next-step submit pull-right" style={{marginRight:'0px'}}>Send Message</button>
                        </div>
                    </div>
                </form>
            </section>
          </div>
        </Modal>

        <Modal show={this.state.showInstructionModal} onHide={this.handleInstructionModalClose}
          dialogClassName="setup-modal">
          <Modal.Header>
            <Modal.Title>Set Up Device</Modal.Title>
            <span className="information_mark tooltip">
              <i className="fa fa-info" />
              <div className="bottom">
                  <h5>Which devices can i connect?</h5>
                  <p>You are able to connect any talking station with an analog output.</p>
                  <i></i>
              </div>
            </span> 
          </Modal.Header>
          
            <section className="login_content">
              <div className="row" style={{padding:'20px'}}>
                <div className="col-md-5 col-sm-5 col-5 text-center">  
                  <img src="./images/device_icon.png" width="80%" />
                </div>
                <div className="col-md-7 col-sm-7 col-7 settings-m400">
                  Setting up the <strong>M400</strong>
                </div>
              </div>
              <div className="row separator" style={{paddingTop:'0px',marginTop:'20px'}}>
                <div className="col-md-12 col-sm-12 col-12" style={{paddingLeft:'30px',paddingRight:'30px'}}>
                  <img src="./images/device_configuration.png" style={{width:'100%'}} />
                </div>
              </div>
              <div className="separator row" style={{padding:'20px 10px 30px 10px'}}>
                <div className="col-sm-7 col-md-7 col-12">
                  <p style={{marginTop:'19px',marginLeft:'20px',color:'black'}}>
                  Still having trouble? <a onClick={(event) => this.setState({showModal: true})}><span style={{color:'#d42927'}}>Send us a message.</span></a>
                  </p>
                </div>
                <div className="col-sm-5 col-md-5 col-12 text-left">
                  <button type="submit" className="btn-login btn-next-step submit" style={{marginLeft:'20px'}} onClick={this.handleInstructionModalClose}>Done</button>
                </div>
              </div>
            </section>
          
        </Modal>
      </div>
    )
  }
}
