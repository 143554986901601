import React, { Component } from 'react'
import AnimateHeight from 'react-animate-height';

export default class HelpItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
        height: 0
    };

    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    const { height } = this.state;
    this.setState({
      height: height === 0 ? 'auto' : 0,
    })
  }

  render() {
    const { height } = this.state;
    return (
      <div className="col-md-12 col-xs-12 col-sm-12">
        <div className="help_question collapse-link" onClick={this.toggle}>
          <a>FQ entry number 1?</a>
          <i className="fa fa-chevron-down pull-right"></i>
          <div className="clearfix"></div>
        </div>
        <AnimateHeight
          duration={300}
          height={ height }>
          <div className="help_answer">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit.
          </div>
        </AnimateHeight>
      </div>
    )
  }
}
