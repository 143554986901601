import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import './index.css';
import LoginContainer from './containers/LoginContainer'
import Help from './containers/Help'
import Devices from './containers/Devices'
import Users from './containers/Users'
import Profile from './containers/Profile'
import Subscription from './containers/Subscription'

import * as serviceWorker from './serviceWorker';
import config from './config';

const requireAuth = (path) =>
{    
    var authToken = localStorage.getItem("silence_token");
    if (!authToken) {
        window.location = "./login"

    } else {
        if (path === "devices") {
            return <Devices />
        } else if (path === "users") {
            return <Users />
        } else if (path === "profile") {
            return <Profile />
        } else if (path === "help") {
            return <Help />
        } else if (path === "payment") {
            return <Subscription />
        }
    }
};

ReactDOM.render(
    <BrowserRouter basename={config.basename}>
        <Switch>
            <Route exact path="/login" render={() => localStorage.getItem("silence_token")?<Redirect to="/devices" />: <LoginContainer/>} />
            <Route exact path="/help" render={() => requireAuth("help")} />
            <Route exact path="/devices" render={() => requireAuth("devices")} />
            <Route exact path="/users" render={() => requireAuth("users")} />
            <Route exact path="/profile" render={() => requireAuth("profile")} />
            <Route exact path="/subscription" render={() => requireAuth("payment")} />
            <Route exact path="/" render={() => <Redirect to="/login" />} />
        </Switch>
    </BrowserRouter>,

    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
